import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { useEnrollCourseWithPromoMutation, useGetUserByUidMutation, useLinkExistingUserWithPromoCodeMutation, useSaveUserJoinReasonMutation } from 'defaultLayout/slice/service'
import { selectUserDetail } from 'defaultLayout/slice/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { authInstance } from 'utils/utils'

export const useAuthCheck = () => {
  const [linkPromoCode, { isSuccess }] = useLinkExistingUserWithPromoCodeMutation()
  const [saveJoinReason, { isSuccess: saveJoinReasonSuccess }] = useSaveUserJoinReasonMutation()
  const [enrollCourse] = useEnrollCourseWithPromoMutation()

  const { actions, dispatch } = useDefaultLayoutSlice()
  const storedUser = useSelector(selectUserDetail)
  const [isAuthenticating, setIsAuthenticated] = useState(true)
  const [getUserByUid, { data, isLoading: isFetchingUser }] = useGetUserByUidMutation()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (data) {
      dispatch(actions.setUserData(data))
      localStorage.setItem('user_type', String(data.user_type))
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem('promo_code')
    }
  }, [isSuccess])
  useEffect(() => {
    if (saveJoinReasonSuccess) {
      localStorage.removeItem('join_reason')
    }
  }, [saveJoinReasonSuccess])
  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged(async (user) => {
      setTimeout(() => {
        dispatch(actions.setIsAuthenticating(false))
        setIsAuthenticated(false)
      }, 1000)
      if (user) {
        const firebaseToken = await user.getIdToken()
        if (user.uid && firebaseToken) {
          // if (!storedUser?.id) {
          // }
          await getUserByUid(user.uid)
          const promo_code = localStorage.getItem('promo_code')
          const join_reason = localStorage.getItem('join_reason')
          if (promo_code) {
            await linkPromoCode({
              promo_code,
            })
          }

          // Only if it is inside a public routes
          if (
            pathname.includes('/login') ||
            pathname.includes('/signup') ||
            pathname === '/' ||
            pathname.includes('/pass-reset') ||
            pathname.includes('/auth') ||
            pathname.includes('/pass-recovery') ||
            pathname.includes('/verify')
          ) {
            const courseId = searchParams.get('courseId')
            const referral = searchParams.get('referral')
            if (courseId && referral) {
              await enrollCourse({
                course_id: courseId,
                promo_code: referral,
              })
            }
            dispatch(actions.redirectUser(courseId ? `/profile/parent/course/${courseId}` : '/'))
          }
        }
        dispatch(actions.setFirebaseToken(firebaseToken))
      }
    })

    return () => unsubscribe()
  }, [])

  return {
    user: data,
    isFetchingUser,
    isAuthenticating,
  }
}
